import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import { LangWebContext } from "./AppContext";

const Header = () => {
  const langWeb = useContext(LangWebContext);
  return (
    <header>
      <NavLink
        exact
        to="/"
        title={langWeb === "fr" ? "Page d'acceuil" : "Home page"}
        className="logo"
      >
        <img
          src={process.env.PUBLIC_URL + "../img/logo/logo_noir.svg"}
          alt="logo lebolloch_dev : Développeur web / intégrateur web"
        />
      </NavLink>
      <ul className="reseaux">
        <li>
          <NavLink
            exact
            to="/"
            title={langWeb === "fr" ? "Page d'acceuil" : "Home page"}
            activeClassName="nav-activ"
          >
            {langWeb === "fr" ? <h2>Acceuil</h2> : <h2>Home</h2>}
          </NavLink>
        </li>
        <li>
          <NavLink
            exact
            to="/skills"
            title={langWeb === "fr" ? "Page des compétences" : "Skills page"}
            activeClassName="nav-activ"
          >
            {langWeb === "fr" ? <h2>Compétences</h2> : <h2>Skills</h2>}
          </NavLink>
        </li>
        <li>
          <NavLink
            exact
            to="/about"
            title={
              langWeb === "fr"
                ? "Page des informations personnelles"
                : "Personal information page"
            }
            activeClassName="nav-activ"
          >
            {langWeb === "fr" ? <h2>À propos</h2> : <h2>About</h2>}
          </NavLink>
        </li>
      </ul>
      <div className="copyright">
        <i className="far fa-copyright"></i>
        <p>2021</p>
        <p>Lebolloch_Dev</p>
      </div>
    </header>
  );
};

export default Header;
