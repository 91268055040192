import React from "react";

const Nav = () => {
  return (
    <>
      <ul className="ul-desktop">
        <li>
          <a
            href="https://www.linkedin.com/in/guillaume-le-bolloch/"
            rel="noopener noreferrer"
            target="_blank"
            title="LinkedIn"
          >
            <i className="fab fa-linkedin-in"></i>
          </a>
        </li>
        <li>
          <a
            href="https://github.com/lebolloch-dev"
            rel="noopener noreferrer"
            target="_blank"
            title="GitHub"
          >
            <i className="fab fa-github"></i>
          </a>
        </li>
        <li>
          <a
            href="https://www.instagram.com/lebolloch_dev/"
            rel="noopener noreferrer"
            target="_blank"
            title="Instagram"
          >
            <i className="fab fa-instagram"></i>
          </a>
        </li>
      </ul>
    </>
  );
};

export default Nav;
