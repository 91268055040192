import React, { useContext, useState } from "react";
import { LangWebContext } from "../components/AppContext";
import Adsgn from "../components/projects/Adsgn";
import Groupomania from "../components/projects/Groupomania";
import LaboT from "../components/projects/LaboT";

const Home = () => {
  const [cardLaboT, setCardLaboT] = useState(false);
  const [cardaDesign, setCardaDesign] = useState(false);
  const [cardGroupomania, setCardGroupomania] = useState(false);
  const test = document.querySelector("html");
  const langWeb = useContext(LangWebContext);

  langWeb === "fr"
    ? (document.title = "LeBolloch-Dev | Acceuil")
    : (document.title = "LeBolloch-Dev | Home");

  if (cardLaboT) {
    document.body.classList.add("no-scroll");
    test.classList.add("no-scroll");
  }

  return (
    <main>
      <section className="home">
        <div className="id-bg"></div>
        {langWeb === "fr" ? (
          <>
            <h1 aria-label="Hey, Je suis Guillaume, Développeur Web">
              <span>Hey,</span>
              <span>Je suis Guillaume,</span>
              <span>Développeur Web Junior</span>
              <span>Prochainement basé sur Montreal</span>
            </h1>
            <p>
              Je suis actuellement à la recherche de nouveaux défis dans
              lesquels je pourrais appliquer mes connaissances en développement
              Front-end. <br />
              Si vous êtes intéressé de travailler avec moi, n'hésitez pas à me
              contacter!
            </p>
          </>
        ) : (
          <>
            <h1 aria-label="Hi, I'm Guillaume, Junior Web Developer">
              <span>Hi,</span>
              <span>I'm Guillaume,</span>
              <span>Junior Web Developer</span>
              <span>Shortly based in Montreal</span>
            </h1>
            <p>
              I'm actually looking for new challenges in which I could apply my
              knowledge in front-end development. <br />
              If you are interested in working with me, don't hesitate to
              contact me!
            </p>
          </>
        )}

        <a
          href="mailto:lebolloch.g@gmail.com"
          title={langWeb === "fr" ? "Contactez moi!" : "Contact me!"}
          className="button-A"
        >
          <span></span>
          {langWeb === "fr" ? <h4>Contactez moi!</h4> : <h4>Contact me!</h4>}
        </a>
        <div className="scroll">
          <span>scroll</span>
          <i className="fas fa-sort-down"></i>
        </div>
        <div className="scroll right">
          <span>scroll</span>
          <i className="fas fa-sort-down"></i>
        </div>
      </section>
      <section className="projects">
        {langWeb === "fr" ? <h2>Projets</h2> : <h2>Projects</h2>}
        <div className="info-projects">
          {langWeb === "fr" ? (
            <p>Voici quelques uns de mes projets</p>
          ) : (
            <p>Here follows some of my projects</p>
          )}
        </div>
        <div className="projects-container">
          <div className="card-parent">
            <div
              className="card-project labo-t"
              onClick={() => setCardLaboT(!cardLaboT)}
            ></div>
          </div>
          <div className="card-parent">
            <div
              className="card-project aDesign"
              onClick={() => setCardaDesign(!cardaDesign)}
            ></div>
          </div>
          <div className="card-parent">
            <div
              className="card-project groupomania"
              onClick={() => setCardGroupomania(!cardGroupomania)}
            ></div>
          </div>
        </div>
        <div className="see-more">
          <button className="button-B">
            {langWeb === "fr" ? <h4>Voir plus ...</h4> : <h4>See more ... </h4>}
          </button>
        </div>

        {cardLaboT && (
          <>
            <div className="info-card">
              <div
                className="closeButton"
                onClick={() => setCardLaboT(!cardLaboT)}
              >
                <span></span>
              </div>
              <LaboT />
            </div>
            <div
              className="unshowCard"
              onClick={() => setCardLaboT(!cardLaboT)}
            ></div>
          </>
        )}
        {cardaDesign && (
          <>
            <div className="info-card">
              <div
                className="closeButton"
                onClick={() => setCardaDesign(!cardaDesign)}
              >
                <span></span>
              </div>
              <Adsgn />
            </div>
            <div
              className="unshowCard"
              onClick={() => setCardaDesign(!cardaDesign)}
            ></div>
          </>
        )}
        {cardGroupomania && (
          <>
            <div className="info-card">
              <div
                className="closeButton"
                onClick={() => setCardGroupomania(!cardGroupomania)}
              >
                <span></span>
              </div>
              <Groupomania />
            </div>
            <div
              className="unshowCard"
              onClick={() => setCardGroupomania(!cardGroupomania)}
            ></div>
          </>
        )}
        <span className="beforeStyle"></span>
      </section>
    </main>
  );
};

export default Home;
