import React, { useContext } from "react";
import { LangWebContext } from "./AppContext";

const LangueSelect = () => {
  const langWeb = useContext(LangWebContext);
  const handleLangFR = () => {
    sessionStorage.setItem("langStatus", "fr");
    window.location.reload();
  };
  const handleLangEN = () => {
    sessionStorage.setItem("langStatus", "en");
    window.location.reload();
  };

  return (
    <>
      <div className="selectLangue">
        {langWeb === "fr" ? (
          <div onClick={handleLangEN} className="langEn">
            <h2 className="notActivLang">EN</h2>
            <h2 className="activLang">FR</h2>
          </div>
        ) : (
          <div onClick={handleLangFR} className="langFr">
            <h2 className="activLang">EN</h2>
            <h2 className="notActivLang">FR</h2>
          </div>
        )}
      </div>

      <div className="selectLangueMobile">
        {langWeb === "fr" ? (
          <div onClick={handleLangEN} className="langEn">
            <h2 className="activLang">FR</h2>
          </div>
        ) : (
          <div onClick={handleLangFR} className="langFr">
            <h2 className="activLang">EN</h2>
          </div>
        )}
      </div>
    </>
  );
};

export default LangueSelect;
