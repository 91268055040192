import React, { useContext } from "react";
import { LangWebContext } from "../components/AppContext";
import Pdf from "../CV/LE_BOLLOCH_Guillaume_CV.pdf";

const About = () => {
  const langWeb = useContext(LangWebContext);

  langWeb === "fr"
    ? (document.title = "LeBolloch-Dev | À propos")
    : (document.title = "LeBolloch-Dev | About");

  return (
    <main className="main-about">
      <section className="about">
        {langWeb === "fr" ? <h2>À propos</h2> : <h2>About</h2>}

        <div className="about-info">
          <article>
            {langWeb === "fr" ? (
              <p>
                Salut ! Je m'appelle Guillaume, je suis un jeune père de famille
                de 31 ans.
                <br /> <br />
                Je suis passionné par l'univers de l'informatique. <br />
                Aprés toutes ces années à être dans les jeux vidéos, j'ai décidé
                de me lancer dans l'aventure du développement web où je suis
                freelance depuis presque 1 ans.
                <br /> <br />
                Je serai prochainement sur Montréal et si vous cherchez
                quelqu'un de motivé et d'ambitieux, alors vous êtes au bon
                endroit!
                <br /> <br />
                Je vous dis à très vite
              </p>
            ) : (
              <p>
                Hello, My name is Guillaume, and I am a 31-year-old father of
                one.
                <br /> <br />I am passionate about the field of IT. <br />
                After spending several years in video games, I decided to dive
                into the world of web development, in which I have been working
                as a freelance for almost one year.
                <br /> <br />
                I will be in Montreal soon and if you are looking for someone
                motivated and ambitious, then you have found the right person!
                <br /> <br />I look forward to hearing from you!
              </p>
            )}

            <a
              href={Pdf}
              without="true"
              rel="noopener noreferrer"
              target="_blank"
              title={langWeb === "fr" ? "Curriculum vitæ" : "Resume"}
              className="button-A"
            >
              <span></span>
              {langWeb === "fr" ? (
                <h4>Télécharger C.V</h4>
              ) : (
                <h4>Download Resume</h4>
              )}
            </a>
            <div className="reseaux-sociaux">
              <ul className="ul-mobile">
                <li>
                  <a
                    href="https://www.linkedin.com/in/guillaume-le-bolloch/"
                    rel="noopener noreferrer"
                    target="_blank"
                    title="LinkedIn"
                  >
                    <i className="fab fa-linkedin-in"></i>
                  </a>
                </li>
                <li>
                  <a
                    href="https://github.com/lebolloch-dev"
                    rel="noopener noreferrer"
                    target="_blank"
                    title="GitHub"
                  >
                    <i className="fab fa-github"></i>
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.instagram.com/lebolloch_dev/"
                    rel="noopener noreferrer"
                    target="_blank"
                    title="Instagram"
                  >
                    <i className="fab fa-instagram"></i>
                  </a>
                </li>
              </ul>
            </div>
          </article>
          <img
            src={process.env.PUBLIC_URL + "/img/logo/photo-id.webp"}
            alt="identity "
          />
        </div>
      </section>
    </main>
  );
};

export default About;
