import { useEffect, useState } from "react";
import { LangWebContext } from "./components/AppContext";
import Routes from "./routes";

function App() {
  const [langWeb, setLangWeb] = useState("en");

  useEffect(() => {
    setLangWeb(sessionStorage.getItem("langStatus"));
  });

  return (
    <LangWebContext.Provider value={langWeb}>
      <Routes />
    </LangWebContext.Provider>
  );
}

export default App;
